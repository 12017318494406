import Cookies from 'js-cookie'

const USER_ID = 'ipsye-front-user_id'
const USER_NAME = 'ipsye-front-user_name'
const ACCOUNT_NAME = 'ipsye-front-account_name'
const LAST_NAME = 'ipsye-front-last_name'

export function setLastName(last_name) {
    Cookies.set(LAST_NAME, last_name)
}
export function getLastName() {
    return Cookies.get(LAST_NAME)
}

export function setAccountName(account_name) {
    Cookies.set(ACCOUNT_NAME, account_name)
}

export function getAccountName() {
    return Cookies.get(ACCOUNT_NAME)
}

export function setUserId(user_id) {
    Cookies.set(USER_ID, user_id)
}

export function getUserId() {
    return Cookies.get(USER_ID)
}

export function removeUserId() {
    Cookies.remove(USER_ID)
}

export function setUserName(user_name) {
    Cookies.set(USER_NAME, user_name)
}

export function getUserName() {
    return Cookies.get(USER_NAME)
}

export function removeUserName() {
    Cookies.remove(USER_NAME)
}

export function clearUser() {
    Cookies.remove(ACCOUNT_NAME)
    Cookies.remove(LAST_NAME)
    removeUserId()
    removeUserName()
}

export function isTestUser() {
    let user_id = getUserId()
    return true
        // if (user_id == '61cd6856a4640b946f8b456f' || user_id == '64b0f34ca4640b167b8b45b2' || user_id == '654de577a4640bc4308b45ee' || user_id == '6215c18ba4640ba1118b466d') {
        //     return true
        // }
        // return false
}