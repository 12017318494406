import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import router from './router'
import UserData from './utils/UserData'

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video;

Vue.prototype.$globalMessage = new Vue();
Vue.prototype.$UserData = UserData
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')