import request from '../utils/request'

export function loginByStudent(student_email, student_pwd) {
    return request.post('./index.php', {
        function: 'loginByStudent',
        student_email,
        student_pwd
    })
}

export function getStudentCases(student_id) {
    return request.post('./index.php', {
        function: 'getStudentCases',
        student_id
    })
}

export function getCaseAndQuestions(case_id, student_id) {
    return request.post('./index.php', {
        function: 'getCaseAndQuestions',
        case_id,
        student_id
    })
}

export function getQuestionAndRecord(question_id, question_record_id, student_id) {
    return request.post('./index.php', {
        function: 'getQuestionAndRecord',
        question_id,
        question_record_id,
        student_id
    })
}

export function answerQuestion(params) {
    return request.post('./index.php', {
        function: 'answerQuestion',
        student_id: params.student_id,
        case_record_id: params.case_record_id,
        question_id: params.question_id,
        answers: params.answers,
        correct: params.correct,
        case_id: params.case_id
    })
}
export function getCaseRecord(case_id, student_id) {
    return request.post('./index.php', {
        function: 'getCaseRecord',
        case_id,
        student_id
    })
}
export function getCaseInfo(case_id) {
    return request.post('./index.php', {
        function: 'getCaseInfo',
        case_id
    })
}

export function getCaseQuestionAndRecords(case_id, student_id) {
    return request.post('./index.php', {
        function: 'getCaseQuestionAndRecords',
        case_id,
        student_id
    })
}


export function checkApplication(student_id) {
    return request.post('./index.php', {
        function: 'checkApplication',
        student_id
    })
}


export function getStripeUrl(application_id) {
    return request.post('./index.php', {
        function: 'getStripeUrl',
        application_id
    })
}

export function getCaseApplicationOrder(application_id) {
    return request.post('./index.php', {
        function: 'getCaseApplicationOrder',
        application_id
    })
}

export function saveAccountForm(form) {
    return request.post('./index.php', {
        function: 'saveAccountForm',
        form
    })
}
export function saveStudentForm(form) {
    return request.post('./index.php', {
        function: 'saveStudentForm',
        form
    })
}

export function checkApplicationNew(student_id) {
    return request.post('./index.php', {
        function: 'checkApplicationNew',
        student_id
    })
}
export function createApplication(student_id) {
    return request.post('./index.php', {
        function: 'createApplication',
        student_id
    })
}

export function getCaseApplication(application_id) {
    return request.post('./index.php', {
        function: 'getCaseApplication',
        application_id
    })
}

export function checkEAEUPAccount(student_id) {
    return request.post('./index.php', {
        function: 'checkEAEUPAccount',
        student_id
    })
}

export function getSessions(class_id,
    student_id) {
    return request.post('./index.php', {
        function: 'getSessions',
        class_id,
        student_id
    })
}


export function getContents({
    session_id,
    student_id
}) {
    return request.post('./index.php', {
            function: 'getContents',
            session_id,
            student_id,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getContent({
    content_id,
    student_id
}) {
    return request.post('./index.php', {
            function: 'getContent',
            content_id,
            student_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}
export function uploadQuiz({
    quiz_id,
    student_id,
    quiz_answer,
}) {
    return request.post('./index.php', {
            function: 'uploadQuiz',
            quiz_id,
            student_id,
            quiz_answer,
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function netService({
    functionName,
    ...p
}) {
    return request.post('./index.php', {
            function: functionName,
            ...p
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function resetQuiz(
    user_id,
    class_id,
    session_id
) {
    return request.post('./index.php', {
            function: 'resetQuiz',
            user_id,
            class_id,
            session_id
        })
        .then(function(response) {
            return response.data
        })
        .catch(function(error) {
            console.log(error);
        })
}

export function getCountries() {
    return request.post('./index.php', {
        function: 'getCountries'
    })
}


export function getPaymentApplication(student_id) {
    return request.post('./index.php', {
        function: 'getPaymentApplication',
        student_id
    })
}