import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUserId } from "../utils/store";

Vue.use(VueRouter)
const routes = [{
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../views/login/index.vue'),
        meta: {
            requiresAuth: false,
            title: 'Login'
        },
    },
    {
        path: '/receipt',
        component: () =>
            import ('../components/receiptT.vue'),
        meta: {
            requiresAuth: true,
            title: 'Receipt'
        },
    },
    {
        path: '/accountForm',
        name: 'indx',
        component: () =>
            import ('../views/accountForm/index.vue'),
        meta: {
            requiresAuth: false,
            title: 'IPSYO'
        },
    },
    {
        path: '/appForm',
        name: 'indx',
        component: () =>
            import ('../views/appForm/index.vue'),
        meta: {
            requiresAuth: false,
            title: 'IPSYO'
        },
    },
    {
        path: '/',
        redirect: '/case-list',
        meta: {
            requiresAuth: true,
            title: 'Home'
        },
    },
    {
        path: '/case-list',
        component: () =>
            import ('../views/Home'),
        meta: {
            requiresAuth: true,
            title: 'Home'
        },
    },
    {
        path: '/order',
        component: () =>
            import ('../views/order.vue'),
        meta: {
            requiresAuth: true,
            title: 'Order'
        },
    },
    {
        path: '/pay',
        component: () =>
            import ('../views/pay.vue'),
        meta: {
            requiresAuth: true,
            title: 'Pay'
        },
    },
    {
        path: '/ipsye-intro',
        component: () =>
            import ('../views/Intro'),
        meta: {
            requiresAuth: true,
            title: 'Intro'
        },
    },
    {
        path: '/ipsye-academy',
        component: () =>
            import ('../views/academy'),
        meta: {
            requiresAuth: true,
            title: 'Intro'
        },
    },

    {
        path: '/content-home',
        component: () =>
            import ('../views/content/Home.vue'),
        meta: {
            requiresAuth: true,
            title: 'Intro'
        },
    },

    {
        path: '/lecture',
        component: () =>
            import ('../views/Lecture'),
        meta: {
            requiresAuth: true,
            title: 'Lecture'
        },
    },
]
const router = new VueRouter({
    linkActiveClass: 'active',
    routes
})
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        var userId = getUserId()
        if (to.meta.requiresAuth && (userId == undefined || userId == '')) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    }
})
export default router