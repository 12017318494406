<template>
    <div class="paymentOver">
        <div class="paper" id="show" :style="myStyle">
            <img src="../assets/receipt_bg.jpg" class="paybackImg">
            <div class="paystudentBlock" style="font-size:14px;line-height:24px;margin-top: 100px;">
                <div>You have successfully paid for the IPsyE System. </div>
                <div>Please find the details of your payment below.</div>
            </div>
            <!--ok le-->
            <div class="paystudentBlock">
                <div class="lineTitle">Student Information</div>
                <div class="txt"><span class="title">Name:</span>{{ item.student_info ?
                    item.student_info.student_lastName_pinyin + ' ' + item.student_info.student_givenName_pinyin : '' }}
                </div>
                <div class="txt"><span class="title">Email:</span>{{ item.student_info ? item.student_info.student_email
                    : '' }}</div>

                <div class="txt"><span class="title">Gender:</span>{{ item.student_info ?
                    (item.student_info.student_gender === '女' ? 'Female' : 'Male') : '' }}
                </div>
                <div class="txt"><span class="title">School:</span>{{ item.account_info ?
                    item.account_info.account_nameEN : '' }}</div>
            </div>


            <div class="paystudentBlock">
                <div class="lineTitle">Order Information</div>
                <div style="margin-top:10px;border:1px solid #606266;font-size: 14px;">
                    <div style="display:flex">
                        <div class="leftTable">Order ID</div>
                        <div class="rightTable">{{ item.app_info._id.$id }}</div>
                    </div>
                    <div style="display:flex">
                        <div class="leftTable">Program</div>
                        <div class="rightTable">IPsyE System</div>
                    </div>
                    <div style="display:flex">
                        <div class="leftTable">Item</div>
                        <div class="rightTable">Subscription Fee</div>
                    </div>
                    <div style="display:flex">
                        <div class="leftTable">Standard Price</div>
                        <div class="rightTable">{{ '100 SGD' }}</div>
                    </div>
                    <div style="display:flex">
                        <div class="leftTable">Discount</div>
                        <div class="rightTable">{{ (100 - item.app_info.total_price) + ' SGD' }}</div>
                    </div>
                    <div style="display:flex">
                        <div class="leftTable">Amount Payable</div>
                        <div class="rightTable">{{ item.app_info.total_price + ' SGD' }}</div>
                    </div>
                </div>
            </div>
            <div class="paystudentBlock">
                <div class="lineTitle">Payment Information</div>
                <div class="txt"><span class="title">Amount Received:</span>{{ item.app_info.total_price + ' SGD' }}
                </div>
                <div class="txt"><span class="title">Payment Method:</span>{{ 'Stripe' }}</div>
                <div class="txt"><span class="title">Payment Time:</span>{{
                    formatDate(item.app_info.application_payment_date_int) }}</div>
                <div class="txt"><span class="title">Payment Status:</span>{{ 'Success' }}</div>
                <div class="txt"><span class="title">Transaction ID:</span>{{ formatTransactionID() }}</div>
                <div class="txt"><span class="title">Amount Refunded:</span>{{ '0' }}</div>
            </div>
        </div>
        <div class="foot" :style="footStyle">
            <div class="cancelBtn" @click="closeReceipt()">Cancel</div>
            <div class="confirmBtn" @click="toDownload">Save as PDF</div>
        </div>
    </div>
</template>
<script>
import {
    getPaymentApplication
} from '../api/ipsye'
import {
    getUserId
} from '../utils/store'
export default {
    data() {
        return {
            // item: {},
            myStyle: {},
            footStyle: {}
        }
    },
    props: {
        item: {
            type: Object,
            default: {}
        }
    },
    watch: {
        item: {
            handler(newVal, oldVal) {
                this.computeHeight()
            },
            immediate: true
        }
    },
    mounted() {
        window.onresize = () => {
            this.computeHeight()
        }
        // this.requestData()
    },

    methods: {

        closeReceipt() {
            this.$emit('closeReceipt')
        },

        formatTransactionID() {
            if (this.item.app_info && this.item.app_info.application_paymentintent_id) {
                return this.item.app_info.application_paymentintent_id
            }
            let json = JSON.parse(this.item.app_info.stripe)
            return json.payment_intent
        },

        formatDate(applicatio_time) {
            let time = applicatio_time * 1000;
            var date = new Date(time);
            let year = date.getFullYear();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            return this.formatMonthToEng(month) + " " + day + ", " + year + ", " + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":" + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ":" + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        },
        formatMonthToEng(month) {
            switch (month) {
                case 1:
                    return "January";
                case 2:
                    return "February";
                case 3:
                    return "March";
                case 4:
                    return "April";
                case 5:
                    return "May";
                case 6:
                    return "June";
                case 7:
                    return "July";
                case 8:
                    return "August";
                case 9:
                    return "September";
                case 10:
                    return "October";
                case 11:
                    return "November";
                case 12:
                    return "December";
            }
        },
        requestData() {
            getPaymentApplication(getUserId()).then((res) => {
                this.item = res.data.data
            })
        },
        computeHeight() {
            const height = document.documentElement.clientHeight
            const newHeight = height - 100
            const rate = newHeight / 1123
            this.myStyle = {
                transform: `scale(${rate})`,
                left: `calc(50% - ${rate * 104}mm)`
            }
            this.footStyle = {
                top: `${height - 45}px`
            }
        },
        open() {

        },
        toDownload() {
            if (!this.item) {
                this.$message.error('Fail')
                return
            }
            const router = this.$router.resolve({
                path: '/receipt',
            })
            window.open(router.href, '_blank')
            this.closeReceipt()
        }
    }
}
</script>

<style lang="scss">
.paymentOver {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);

    .foot {
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;

        .cancelBtn {
            background: #fff;
            color: #0E4890;
            font-family: "DDINAlternateRegular";
            cursor: pointer;
            font-size: 18px;
            border: 2px solid #0E4890;
            border-radius: 6px;
            width: 45%;
            max-width: 200px;
            height: 40px;
            font-weight: bold;
            text-align: center;
            line-height: 40px;
        }

        .confirmBtn {
            background: #0E4890;
            border: 2px solid #0E4890;
            color: #fff;
            font-family: "DDINAlternateRegular";
            cursor: pointer;
            border-radius: 6px;
            font-size: 18px;
            width: 45%;
            max-width: 200px;
            height: 40px;
            font-weight: bold;
            text-align: center;
            line-height: 40px;
            margin-left: 10px;
        }
    }
}

.paybackImg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.paystudentBlock {
    margin: 36px auto;
    padding: 0 16px;
    color: #262727;

    .lineTitle {
        font-size: 18px;
        font-family: "DDINAlternateRegular";
        font-weight: normal;
        color: #262727;
        font-weight: bold;
        margin-bottom: 12px;
    }

    .title {
        font-weight: bold;
        margin-right: 10px;
    }

    .leftTable {
        padding: 5px 10px;
        width: 40%;
        border-right: 1px solid #606266;
        border-bottom: 1px solid #606266;
    }

    .rightTable {
        padding: 5px 10px;
        width: 60%;
        text-align: right;
        border-bottom: 1px solid #606266;
    }

    .txt {
        margin-top: 15px;
        font-size: 16px;
    }
}

.paytop {
    width: 190mm;
    height: 12%;
    display: flex;
    justify-content: flex-end;
}

.paper {
    width: 210mm;
    height: 277.14mm;
    padding: 10mm;
    overflow: hidden;
    position: relative;
    transform-origin: 0 0;
    top: 50px;
}
</style>
